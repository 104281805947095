import { Component, Injector, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AbstractComponent, FlowService } from 'lib-trend-core';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'flow-embeded-component',
  templateUrl: 'flow-embeded.component.html',
})
export class FlowEmbededComponent extends AbstractComponent implements OnInit {

  urlEmbeded: SafeResourceUrl = undefined
  public flowName: string;

  constructor(
    injector: Injector,
    private domSanitizer: DomSanitizer,
    public flowService: FlowService,
  ) {
    super(injector)
  }

  ngOnInit() {
    const idFlow = super.getParam('idFlow');
    const url = `${environment.trendFlow}?idFlow=${idFlow}`;
    this.urlEmbeded = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    this.getFlow(idFlow)
  }

  private getFlow(idFlow: string) {
    this.flowService.getById(idFlow).subscribe({
      next: (value) => {
        this.flowName = value.name
      }
    })
  }

}