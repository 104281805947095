import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { AbstractComponent, ConfirmationComponent, Flow, FlowService, Pager, UtilHelper } from "lib-trend-core";
import { environment } from "../../../environments/environment";
import { FlowFormComponent } from "./flow-form/flow-form.component";

@Component({
  selector: 'flow-component',
  templateUrl: 'flow.component.html',
})
export class FlowComponent extends AbstractComponent implements OnInit {
  pager: Pager<Flow> = new Pager<Flow>({ perPage: 50 });
  fomattedDate: string

  displayedColumns: string[] = ['name', 'description', 'actions']

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private flowService: FlowService,
  ) {
    super(injector);
    this.createForm();
  }

  ngOnInit() {
    this.formGroup.controls['flow'].valueChanges.subscribe(value => {
      this.getFlows();
    });
    this.getFlows();

  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      flow: [null],
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FlowFormComponent)
  }

  delete(flowId: string) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result) === true) {
        this.flowService.delete(flowId).subscribe({
          next: (value) => {
            this.getFlows()
            this.alertService.success()
          },
          error: (err) => this.alertService.error(err.error.message),
        })
      }
    })
  }

  showOptions(id: string) {
    this.goToWebService(id)
  }

  goToWebService(id: string) {
    const idUser = super.getIDCurrentUser();
    const idCompany = super.getIDCurrentCompany();
    const base64 = UtilHelper.generateTokenTowardsApps(idUser, idCompany);
    window.open(`${environment.trendFlow}/${id}?token=${base64}`, '_blank');
  }

  getFlows() {
    this.flowService.getAll(
      this.pager.page,
      this.pager.perPage,
      this.searchString,
      this.searchParams).subscribe({
        next: (pager: Pager<Flow>) => {
          this.pager = pager
        },
        error: (err) => this.alertService.error(err.error.message),
      })
  }
}


