<div class="dialog flex flex-col max-w-[512px] p-6" [ngClass]="{'items-center': isLoading}">
  <div
    class="h-[64px] w-[64px] mb-2 rounded-full bg-[#E6E6FF] border-[#F1F1FF] border-8 flex justify-center items-center"
  >
    <i
      class="ph ph-tree-structure text-[24px] font-bold text-center text-[#4213F6]"
    ></i>
  </div>
  <p class="text-lg font-bold">Novo fluxo</p>
  <p class="mb-4">
    {{isLoading ? 'Você será redirecionado para a página de criação do fluxo' : 'Para começar, dê um nome o seu fluxo que irá te ajudar a identificar-lá facilmente na lista.'}}
  </p>
  <form
    [formGroup]="formGroup"
    role="form"
    name="creaFlowForm"
    (ngSubmit)="createFlow()"
    *ngIf="!isLoading"
  >
    <p class="text-lg font-bold">Nome</p>
    <input
      matInput
      formControlName="name"
      name="name"
      class="w-full border rounded-lg h-[40px] mb-4 px-3"
      placeholder="ex: Novo atendimento"
      required
    />
    <field-error-component
      [field]="'name'"
      [label]="'Nome'"
      [formGroup]="formGroup"
    >
    </field-error-component>
    <p class="text-lg font-bold">Descrição</p>
    <input
      matInput
      formControlName="description"
      name="description"
      class="w-full border rounded-lg h-[40px] px-3"
      placeholder="ex: Novo atendimento para automações"
      required
    />
    <field-error-component
      [field]="'description'"
      [label]="'Descrição'"
      [formGroup]="formGroup"
    >
    </field-error-component>

    <div class="flex flex-row justify-between mt-8">
      <button
        class="border rounded-lg min-w-[228px] py-[10px] text-[#344054] font-bold"
        [mat-dialog-close]="true"
      >
        Cancelar
      </button>
      <button
        class="border rounded-lg bg-[#4213F6] min-w-[228px] py-[10px] text-white font-bold"
      >
        Adicionar novo fluxo
      </button>
    </div>
  </form>
  <mat-spinner class="mr-4" [diameter]="25" [color]="'accent'" mode="indeterminate" *ngIf="isLoading"></mat-spinner>
</div>
