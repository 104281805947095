import { Component, Inject, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, Flow, FlowService } from 'lib-trend-core';


export interface DialogData {
  name: string
  description: string
}

@Component({
  selector: 'flow-form-component',
  templateUrl: './flow-form.component.html',
})
export class FlowFormComponent extends AbstractComponent {
  public isLoading: boolean
  private timeOut

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<FlowFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public flowService: FlowService,
  ) { 
    super(injector)
    this.createForm() 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required],
      description: [null, Validators.required],
    })
  }

  redirectToFlowBuilder(idFlow: string) {
    clearTimeout(this.timeOut)
    this.timeOut = setTimeout(() => {
      this.router.navigateByUrl(`/flow/edit/?idFlow=${idFlow}`)
      this.dialogRef.close();
      this.isLoading = false;
    }, 3000)
  }


  createFlow(): void {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup)
      return
    }

    const flow = {} as Flow
    flow.name = this.formGroup.get('name').value
    flow.description = this.formGroup.get('description').value
    this.isLoading = true
    this.flowService.create(flow).subscribe({
      next: (value) => {
        this.redirectToFlowBuilder(value._id)
      }
    })
  }

}
