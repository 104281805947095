import { NgFor, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from 'lib-trend-core';
import { FlowEmbededComponent } from './flow-embeded/flow-embeded.component';
import { FlowFormComponent } from './flow-form/flow-form.component';
import { FlowRoutingModule } from './flow-routing.module';
import { FlowComponent } from './flow.component';

@NgModule({
  imports: [
    SharedModule,
    FlowRoutingModule,
  ],
  declarations: [
    FlowComponent,
    FlowFormComponent,
    FlowEmbededComponent,
  ],
  exports: [
    FlowComponent,
    FlowFormComponent,
    FlowEmbededComponent,
  ],
})
export class FlowModule { }
