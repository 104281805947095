import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { FlowEmbededComponent } from './flow-embeded/flow-embeded.component'
import { FlowComponent } from './flow.component'

const routes: Routes = [
  { path: '', component: FlowComponent },
  { path: 'edit/:idFlow', component: FlowEmbededComponent },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FlowRoutingModule { }
